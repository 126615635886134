import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/v1/Footer.js';
import { Footer as FooterV2 } from '../components/v2/Footer.js';
import Header from '../components/v1/Header.js';
import { Header as HeaderV2 } from '../components/v2/Header.js';
import ParcelInfo from '../components/v1/PacrcelInfo.js';
import KnowMore from '../components/v1/KnowMore.js';
import EmailForm from '../components/v1/EmailForm.js';
import { getParcelInfo }  from '../services/index.js';
import { getBaseURL } from '../services/parcels.js'
import { ReactComponent as Chevron } from './../assets/arrow_forward_ios.svg'
import { ReactComponent as ParcelSVG } from './../assets/package_2.svg'
import TrackingPixel from '../components/v2/trackingPixel.js';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert } from '@mui/material';
import backgroundSVG from './../assets/background-mob.svg'
import "./SlideLeft.css"

export function ParcelChoices (){

  let { parcelId } = useParams();

  const [parcel, setParcel] = useState({});

  useEffect(() => {

    const datafetch = async () => {
        let data = await getParcelInfo(parcelId);
        process.env.REACT_APP_DEBUG === true && console.log(data)

        if(data.status === 200 ) {
          if(data.results.clientFrontWebVersion	!== '1'){
            data.results.clientFrontWebVersion = '2'; //If the clientFrontWebVersion is not 1 (the old interface) then it is set to 2 by default(the new interface)
          }
          setParcel(data.results);
        }else{
          setParcel({error: data.status});
        }

    }

    datafetch();

  }, [parcelId]);

  let style = {
    parcelChoiceType1 : {
        backgroundColor: '#1E1E1E',
        height: 'auto',
        width: '100%',
        margin: '0',
        padding: '0',
    },
    parcelChoiceType2 : {
      backgroundColor: '#FFFFFF',
      backgroundImage: `url(${backgroundSVG})`,
      backgroundSize: 'cover',
      backgroundPosition: 'right 50% top -50vw',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%',
      marginBottom: '8rem',
    },
    button: {
      borderRadius: '20px',
      padding: '0.3em 0.8em 0.3em 0.8em',
      boxShadow: '0px 0px 16px 0px #878787',
      fontSize: '.9rem',
      textAlign: 'left',
      backgroundColor: '#FFFFFF',
  },
  icon: {
    marginRight: '1rem',
    width: '2.5rem',
    height: '2.5rem',
    padding: '.5rem',
    backgroundColor: '#56a6c3',
    borderRadius: '100%',
  },
  subtitle: {
    fontSize: '1.2rem',
    fontWeight: 700,
    textAlign: 'left',
    color: '#56a6c3',
    marginLeft: '1rem'
  },
  description: {
    fontWeight: 300,
  },
  textContent: {
    textAlign: 'center',
  },
}

  const onClik = async (path) => {
    document.querySelector(".parcelChoice").classList.add("slideLeft")
    const baseURL = getBaseURL();
    const url = baseURL+path+`/${parcel.id}?type=${parcel.type}`
    window.open(url, '_self');
    await new Promise(resolve => setTimeout(resolve, 2000));
    document.querySelector(".parcelChoice").classList.remove("slideLeft") // In case the user goes back to the page
  }

  const searchParams = new URLSearchParams(window.location.search);

  const routing = searchParams.get('403');
  const partner = searchParams.get('partner');

  const renderContent = () => {
    if(routing && routing === 'MR') {
      return <>{'11967379470101902543160060'}</>;
    }
    else if(partner && partner === 'MR') {
      return <>{'11967379470101902543160060'}</>;
    }
    else if (parcel && parcel.clientActionsList && parcel.clientFrontWebVersion === '2') {
      const clientActionsList = parcel.clientActionsList.map((action, index) => {
          return (<div className="row justify-content-center text-left my-2 mx-2" key={index}>
            <button type="submit" onClick={() => onClik(`${action.url}`)} className="btn btn-secondary mt-4 text-black d-flex justify-content-between align-items-center" style={style.button}>
              {
                action.icon? <img src={require(`./../assets/${action.icon}.svg`)} alt="icon" className='icon' style={style.icon} /> : <div style={style.icon}/>
              }
              <div className="textContent" style={style.textContent}>
              <b>{action.name}</b><br/>
              <div style={style.description}>{action.description}</div>
              </div>
              <Chevron />
            </button>
          </div>)
        }
      );
      return (
          <div className="parcelChoice" style={style.parcelChoiceType2}>
              <HeaderV2 content="identifiant" tripCount={parcel.usage} />
              <div className="w-100 text-black p-4" style={style.mainContent}>
                <div style={style.subtitle}>
                  {/*Here we can put a subtitle if we want*/}
                </div>
                {clientActionsList}
              </div>
              <FooterV2 content="identifiant" tripCount={parcel.usage} tag={parcel.tag}/>
              <TrackingPixel clientID={"0000000000"} event={"b2c_pageview"} parcelID={parcel.id}/>
          </div>
      );
    }else if (parcel && parcel.clientFrontWebVersion === '2'){
      return (
        <div className="parcelChoice" style={style.parcelChoiceType2}>
            <HeaderV2 content="identifiant" tripCount={parcel.usage} />
            <div className="w-100 text-black p-4" style={style.mainContent}>
              <div style={style.subtitle}>{/*Here we can put a subtitle if we want*/}</div>
              <div className="row justify-content-center text-left my-2 mx-2" key={1}>
                <button type="submit" onClick={() => onClik(`retours/emballage/laposte`)} className="btn btn-secondary mt-4 text-black d-flex justify-content-between align-items-center" style={style.button}>
                    <ParcelSVG alt="icon" style={style.icon} />
                  <div className="textContent" style={style.textContent}>
                    <b>Je restitue un emballage vide</b><br/>
                    <div style={style.description}>{/*Ici on peut mettre une description si on veut*/}</div>
                  </div>
                  <Chevron />
                </button>
                </div>
            </div>
            <FooterV2 content="identifiant" tripCount={parcel.usage} tag={parcel.tag}/>
            <TrackingPixel clientID={"0000000000"} event={"b2c_pageview"} parcelID={parcel.id}/>
        </div>
        )
    } else if (parcel && parcel.clientActionsList && parcel.clientFrontWebVersion === '1'){
      return (
        <div className="parcelChoice" style={style.parcelChoiceType1}>
          <Header parcel={parcel} />
          <ParcelInfo parcel={parcel} />
          <KnowMore parcel={parcel} />
          <EmailForm />
          <Footer marginTop="15%" />
        </div>
      );
    } else if (parcel.error){
      return (
        <Alert severity="error">Erreur {parcel.error} lors de la récupération des données du colis.</Alert>
      );
    } else {
        //While waiting for the data to be fetched, a loading spinner is displayed
        return (
          <div className='d-flex h-100 justify-content-center align-items-center '>
            <CircularProgress />
          </div>
          );
    }

  }

  return (
      renderContent()
  );

}
