import React from 'react';
import { ReactComponent as MPfull} from './../../assets/MOBIUSPACK.svg'
import { Typography } from '@mui/material';


export const Header = ({clientLogo = '', clientURL = ''}) => {
  
  const openNewTab = (url) => () => {
    window.open(url, '_blank').focus();
  }

  const style = {
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5rem 2rem 0',
    },
    logo: {
      width: '100%',
      maxWidth: '300px',
      height: 'auto',
      maxHeight: '100px',
      cursor: 'pointer',
    },
    subtext: {
      fontSize: '1.5rem',
      textAlign: 'center',
      margin: '1rem 0',
    }
  };


  return (
    <div className="header container mb-10" style={style.header}>
      {
        clientLogo?
        <img src={clientLogo} alt="client logo" onClick={openNewTab(clientURL)} style={style.logo}/>
        :
        <MPfull onClick={openNewTab("https://mobiuspack.com/")} style={style.logo}/>
      }
      <Typography variant="h4" component="div"  style={style.subtext}>
        Vous venez de recevoir un colis <b>réemployable</b> !
      </Typography>
    </div>
  );

};

export default Header;


 