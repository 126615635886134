import Stories from 'react-insta-stories';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ReturnButton } from '../components/v2/returnButton.js';
import { getParcelInfo }  from '../services/index.js';
import Hotjar from '@hotjar/browser';

export function ReturnParcelLaPoste() {
    //Retour emballage via La Poste
    let { parcelId } = useParams();
    let parcelType = new URLSearchParams(window.location.search).get('type').toUpperCase();
    const [showReturnButton, setShowReturnButton] = useState(false);
    const [sawSurvey, setSawSurvey] = useState(false); //If the survey was shown to the user
    const [parcel, setParcel] = useState({}); //Parcel data
    console.log(parcelType);

    const handleStoryEnd = (storyIndex, stories) => {
        if (storyIndex === stories.length - 1) {
            showHotjar();
            setShowReturnButton(true);
        }
        else {
            setShowReturnButton(false);
        }
    };

    useEffect(() => {
        const datafetch = async () => {
            const data = await getParcelInfo(parcelId);
            process.env.REACT_APP_DEBUG === true && console.log(data);
            setParcel(data.results);
        };
        datafetch();
    }, [parcelId]);

    const storiesBox = [
        {
            url: "https://video.mobiuspack.io/fr/1B/1B 01.mp4",
            type: 'video',
            preloadResource: true,
        },
        {
            url: "https://video.mobiuspack.io/fr/1B/1B 02.mp4",
            type: 'video',
            preloadResource: true,
        },
        {
            url: "https://video.mobiuspack.io/fr/1B/1B 03.mp4",
            type: 'video',
            preloadResource: true,
        }
    ];

    const storiesSouple = [
        {
            url: "https://video.mobiuspack.io/fr/2B/2B 01.mp4",
            type: 'video',
            preloadResource: true,
        },
        {
            url: "https://video.mobiuspack.io/fr/2B/2B 02.mp4",
            type: 'video',
            preloadResource: true,
        },
        {
            url: "https://video.mobiuspack.io/fr/2B/2B 03.mp4",
            type: 'video',
            preloadResource: true,
        },
        {
            url: "https://video.mobiuspack.io/fr/2B/2B 04.mp4",
            type: 'video',
            preloadResource: true,
        }
    ];

    const showHotjar = () => {
        if (parcel.clientSurvey.b2c && !sawSurvey) {
            try {
                Hotjar.init(siteId, hotjarVersion);
                console.log("Hotjar loaded");
                setSawSurvey(true);
            } catch (e) {
                console.error("Hotjar failed to load", e);
                setSawSurvey(true);
            }
        } else {
            console.log("Hotjar not loaded (not the correct env, already loaded or disabled for this client.)");
        }
    };

    const siteId = 3803943;
    const hotjarVersion = 6;

    return (
        <div className="return">
        <script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>
            {
                parcelType === 'BOX' ? (
                    <div>
                        {/*Retour BOX*/}
                        <Stories
                            id={"return-story"}
                            height={window.innerHeight}
                            width={window.innerWidth}
                            onStoryStart={(storyIndex) => handleStoryEnd(storyIndex, storiesBox)}
                            stories={storiesBox}
                        />
                    </div>
                ) :  (
                    <div>
                        {/*Retour SOUPLE*/}
                        <Stories
                            id={"return-story"}
                            height={window.innerHeight}
                            width={window.innerWidth}
                            onStoryStart={(storyIndex) => handleStoryEnd(storyIndex, storiesSouple)}
                            stories={storiesSouple}
                        />
                    </div>
                )
            }
            <ReturnButton
            label={"Où déposer mon colis?"}
            targetUrl={"https://localiser.laposte.fr/"}
            visible={showReturnButton}
            />
            
        </div>
    );
}