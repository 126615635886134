import React, { useState, useEffect } from 'react'
import { ReactComponent as EmballageSVG } from './../../assets/emballage.svg'
import { ReactComponent as ArticleSVG } from './../../assets/article.svg'
import { ReactComponent as DropUpSvg } from './../../assets/expand_circle_up.svg'
import { ReactComponent as DropDownSVG } from './../../assets/expand_circle_down.svg'
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Fab, Typography, Link } from '@mui/material'
import './Footer.css'

export function Footer ({content = '', tripCount = 0, tag = 'MPXXXXXX'}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [touchStartY, setTouchStartY] = useState(0);
    const [touchEndY, setTouchEndY] = useState(0);

    const style = {
        footer: {
            marginTop: 'auto',
            minHeight: '8rem',
            bottom: '0',
            backgroundColor: '#2EA9C7',
            borderRadius: '5% 5% 0% 0%',
            boxShadow: '0px 0px 16px 0px #878787',
            width: '100%'
        },
        shipFooter: {
          whiteSpace: 'nowrap',
          display: 'inline', 
        },
        arrowFooter: {
          width: '100%',
          height: '100%',
          marginBottom: '-40px'
        },
        dropIcon: {
          maxHeight: "30px",
          marginTop: "10px",
        }
    };
    const StyledFab = styled(Fab)({
      position: 'absolute',
      zIndex: 1,
      top: -10,
      left: 0,
      right: 0,
      margin: '0 auto',
      boxShadow: 'none',
      width: '100px',
      height: '20px',
      borderRadius: '10px',
      minHeight: '0 !important',
    });

    const handleToggle = async () => {
      if (isAnimating) return;
      setIsAnimating(true);
      const footer = document.getElementById('footer');
      if (!footer) return;
    
      // Slide out first
      footer.classList.remove('visible');
    
      // Wait for the transition to complete before updating state
      await new Promise(resolve => setTimeout(resolve, 300));
    
      // Toggle state after animation
      setIsExpanded(prev => !prev);
    
      // Slide back in
      footer.classList.add('visible');
      await new Promise(resolve => setTimeout(resolve, 300));
      setIsAnimating(false);
    };

    const handleTouchStart = (e) => {
      setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
      setTouchEndY(e.touches[0].clientY);
    };

    useEffect(() => {
      const handleTouchEnd = () => {
        if (touchStartY - touchEndY > 50) {
          // Swipe up
          if (!isExpanded){
            handleToggle(true);
          }
          
        }
  
        if (touchEndY - touchStartY > 50) {
          // Swipe down
          if (isExpanded){
            handleToggle(false);
          }
        }
      };
      const footer = document.getElementById('footer');
      if (footer) {
        footer.addEventListener('touchstart', handleTouchStart);
        footer.addEventListener('touchmove', handleTouchMove);
        footer.addEventListener('touchend', handleTouchEnd);
      }


      return () => {
        if (footer) {
          footer.removeEventListener('touchstart', handleTouchStart);
          footer.removeEventListener('touchmove', handleTouchMove);
          footer.removeEventListener('touchend', handleTouchEnd);
        }
      };
    });

    const renderContent = (content) => {
      let tripCountText
      if (Number(tripCount)) {
        tripCountText = `Expédition${tripCount>1?"s":""} réalisée${tripCount>1?"s":""} : ${tripCount} / 100`
      } else {
        tripCountText = `Expédition réalisée : 1 / 100`
      }
        let carouselItems = []
        if( content === 'emballage') {
          return (
            <div className="fs-6 text-center text-white">
              <div className="row"> <EmballageSVG style={style.arrowFooter}/> </div>
              <div className="flex-row justify-content-center">
                <span className='fw-bold' style={style.shipFooter}>MOBIUS # </span>  
                <span className='fw-light' style={style.shipFooter}> {tag} </span> 
              </div>
              <div className="row justify-content-center mb-3">{tripCountText}</div>
            </div>
          );
        } else if (content === 'article') {
            return (
            <div className="fs-6 text-center text-white">
              <div className="row"> <ArticleSVG style={style.arrowFooter} /> </div>
              <div className="flex-row justify-content-center">
                <span className='fw-bold' style={style.shipFooter}>MOBIUS # </span>  
                <span className='fw-light' style={style.shipFooter}> {tag} </span> 
              </div>
              <div className="row justify-content-center mb-3">{tripCountText}</div>
            </div>)
        }
        else if (content === 'home') {
            carouselItems = ['En savoir plus sur MOBIUSpack et son engagement']
            return carouselItems.map((item, index) => (
              <div className="fs-6 text-center text-white" key={index}>
                <a href='https://mobiuspack.com/impact' className='text-white'>{item}</a>
              </div>
            ));
        }
        else if (content === 'identifiant') {
            return (
              <AppBar id="footer" className='visible' position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, borderRadius: "10px 10px 0 0" }} onClick={handleToggle}>
              <Toolbar color="primary" >
                <StyledFab color="primary" aria-label="drop-up">
                  {isExpanded ?
                  <DropDownSVG style={style.dropIcon} />
                  :
                  <DropUpSvg style={style.dropIcon}/>
                  }
                </StyledFab>
                {isExpanded ? (
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left', margin: "20px auto auto 1rem", maxWidth: "80%"}}>
                  <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                    <b>Déjà {tripCount} utilisation{tripCount>1?"s":""} !</b>
                  </Typography>
                  <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }} style={{lineHeight: '1.4', margin: "1rem auto", textAlign: 'justify', textJustify: 'inter-word'}}>
                    Cet emballage a été conçu pour être utilisé 100 fois (et plus).<br/>
                    En le renvoyant, vous participez à sa réutilisation et aussi à la réduction de déchets.<br/>
                  </Typography>
                    
                  
                  <Link color='inherit' href='https://mobiuspack.com/' style={{marginBottom: "10px"}}>En savoir + sur MOBIUSpack</Link>
                  </div>
                ) : (
                <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }} style={{textAlign: 'center', margin: "20px auto auto 1rem"}}>
                  Déjà {tripCount} utilisation{tripCount>1?"s":""} !<br/>
                  <b>Et si il en fallait 1 de plus ?</b>
                </Typography>)}
              </Toolbar>
            </AppBar>
            );
        }
        else {
          return (<div></div>)
        }

    }

    return renderContent(content);

}

export default Footer;