import React from 'react';
import { Router } from './services/index.js'
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/materialTheme.js';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={ Router } />
    </ThemeProvider>
  );
}

export default App;
