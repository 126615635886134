import "./returnButton.css";
import React from "react";
import { ReactComponent as Forward } from "./../../assets/forward_white.svg";
import { ReactComponent as Chevron } from "./../../assets/arrow_forward_ios.svg";

export function ReturnButton({ label, targetUrl, visible }) {
  const [slideRight, setSlideRight] = React.useState(false);
  const style = {
    button: {
        borderRadius: '20px',
        padding: '0.3em 0.8em 0.3em 0.8em',
        boxShadow: '0px 0px 16px 0px #878787',
        fontSize: '.9rem',
        textAlign: 'left',
        backgroundColor: '#FFFFFF',
    },
    icon: {
      marginRight: '1rem',
      width: '2.5rem',
      height: '2.5rem',
      padding: '.5rem',
      backgroundColor: '#56a6c3',
      borderRadius: '100%',
    },
    subtitle: {
      fontSize: '1.2rem',
      fontWeight: 700,
      textAlign: 'left',
      color: '#56a6c3',
      marginLeft: '1rem'
    },
    description: {
      fontWeight: 300,
    },
    floatingButton: {
      position: 'fixed',
      bottom: '2rem',
      zIndex: 1000,
    },
  };

    const handleClick = (path) => {
        return async () => {
          setSlideRight(true);
          window.open(path, '_self');
          await new Promise(resolve => setTimeout(resolve, 2000));
          setSlideRight(false); // In case the user goes back to the page
        }
    }
  return (
    <div className={`w-100 text-black p-4 ${visible&& "visible"} ${slideRight&&"slideRight"}`} id={"return-button"} style={style.floatingButton}>
        <div className="row justify-content-center text-left my-2 mx-2" key={1}>
        <button type="submit" onClick={handleClick(targetUrl)} className="btn btn-secondary mt-4 text-black d-flex justify-content-between align-items-center" style={style.button}>
            <Forward alt="icon" style={style.icon} />
            <div className="textContent" style={style.textContent}>
            <b>{label? label : "Où déposer mon colis?"}</b><br/>
            <div style={style.description}>{/*Ici on peut mettre une description si on veut*/}</div>
            </div>
            <Chevron />
        </button>
        </div>
    </div>
  );
}