import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2ea9c7',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
        root: {
            textTransform: 'none'
        }
    }
},
});

export default theme;