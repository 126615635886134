import {Footer as FooterV2} from '../components/v2/Footer';
import {Header as HeaderV2} from '../components/v2/Header';
import React from 'react';
import { getBaseURL } from '../services/parcels.js'

export function Home() {

    const style = {
        return: {
            backgroundColor: '#FFFFFF',
            height: 'auto',
            width: '100%',
            margin: '0',
            padding: '0',
        },
    };

    const actionsList = [      
        {
            name: 'Je scan mon MOBIUSpack ',
            url : getBaseURL()
        },
    ];

    const listRendering = (list = []) => {
        return list.map((item, index) => (
            <div key={index} className="row justify-content-center text-center my-2 mx-2">
                <button type="button" onClick={() => onClick()} className="btn btn-primary mt-4 fs-5 text-white text-uppercase w-60">
                    {item.name}
                </button>
            </div>
        ));
    };

    const onClick = () => {
       
        console.log( "Play with camera and open the good page with parcel number")

    };

    return (
        <div className="return" style={style.return}>
            <HeaderV2 content="home" />
            <div className="w-100 text-black p-4">
                {listRendering(actionsList)} 
            </div>
            <FooterV2 content="home" />
        </div>
    );
}